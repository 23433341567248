export const dataTracings = [
  "ACEPTA_CARGO",
  "ACTO_PERICIAL_REALIZADO",
  "PERICIA_REALIZADA",
  "SENTENCIA_O_CONVENIO_DE_PARTES",
  "HONORARIOS_REGULADOS",
  "EN_TRATATIVA_DE_COBRO",
  "COBRADO",
];

export const dataPriorities = [
  "NULA",
  "BAJA",
  "MEDIA",
  "ALTA",
  "URGENTE",
  "INACTIVO",
];
